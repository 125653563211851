const linkResolver = (doc) => {
  switch (doc.type) {
    case 'page':
      return `/${doc.uid}`

    case 'article':
      return `/${process.env.GATSBY_PRISMIC_ARTICLE_PAGE}/${doc.uid}`

    case 'cat':
      return `/${process.env.GATSBY_PRISMIC_ARTICLE_PAGE}/categorie/${doc.uid}`

    default:
      return '/'
  }
}

export default linkResolver
