import React from 'react'
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'

import linkResolver from '../lib/link-resolver'

const PreviewPage = () => {
  return <div>loading preview...</div>
}

export default withPrismicPreviewResolver(PreviewPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
  },
])
